import { gql } from "@apollo/client";

export const getClientByAuthId = gql`
  query client($id: String!) {
    user(where: { cognito_id: { _eq: $id } }) {
      id
      cognito_id
      title
      name: given_name
      surname: family_name
      email
      gender
      birthday
      phoneNumber: contact_number
      points
      eLearningStudentId: elearning_id
      account: stripe_id
      createdAt: created_at
      updatedAt: updated_at
      set_password
      signed_in
      code
      registrations(where: { type: { _neq: "CANCELLED" } }) {
        created_at
        event_id
        enrollment_id
        id
        session_id
        status
        type
        updated_at
        user_id
        event {
          detail {
            title
            eLearningCourseId: elearning_course_id
          }
          start
          end
          price: seat_price
          createdAt: created_at
        }
      }
    }
  }
`;

export const getClient = gql`
  query Client($id: uuid!) {
    client: user(where: { id: { _eq: $id } }, limit: 1) {
      id
      cognito_id
      title
      name: given_name
      surname: family_name
      email
      phoneNumber: contact_number
      birthday
      gender
      points
      account: stripe_id
      createdAt: created_at
      updatedAt: updated_at
      registrations(where: { type: { _neq: "CANCELLED" } }) {
        created_at
        event_id
        id
        session_id
        status
        type
        updated_at
        user_id
        event {
          detail {
            title
            eLearningCourseId: elearning_course_id
          }
          start
          end
          price: seat_price
          createdAt: created_at
        }
      }
      forms: user_forms(where: { user: { _eq: $id } }) {
        formByForm {
          id
          title
        }
      }
    }
  }
`;

export const getCourses = gql`
  query GetCourses {
    registration {
      id
      status
      type
      created_at
      event {
        id
        start
        date
        type
        detail {
          id
          title
        }
      }
    }
  }
`;

export const getAllEvents = gql`
  query getAllEvents {
    event_detail(
      order_by: { title: asc }
      where: {
        _and: [
          { archived: { _neq: true } }
          { contentful_id: { _is_null: false } }
        ]
      }
    ) {
      id
      title
      contentful_id
    }
  }
`;

export const InsertInvitation = gql`
  mutation InsertInvitation($data: event_session_invitation_insert_input!) {
    insert_event_session_invitation_one(object: $data) {
      id
    }
  }
`;

export const getAccountData = gql`
  query ClientData($id: uuid!, $startOfDay: timestamptz!) {
    user: user_by_pk(id: $id) {
      id
      created_at
      points
    }
    elearning: online_registration(where: { user_id: { _eq: $id } }) {
      id
      added
      complete
      courseId: coassemble_course_id
      groupId: coassemble_group_id
      data
      created_at
    }
    modelSessionCount: registration_aggregate(
      where: { _and: [{ user_id: { _eq: $id } }, { type: { _eq: "MODEL" } }] }
    ) {
      aggregate {
        count
      }
    }
    futureModelSessions: registration(
      where: {
        _and: [
          { user_id: { _eq: $id } }
          { type: { _eq: "MODEL" } }
          { session: { start: { _gte: $startOfDay } } }
        ]
      }
    ) {
      id
      status
      type
      enrollment_id
      createdAt: created_at
      updatedAt: updated_at
      session {
        id
        start
        end
      }
      event {
        id
        start
        end
        date
        detail {
          id
          title
          assets
        }
      }
    }
    registrations: registration(
      where: {
        _and: [
          { user_id: { _eq: $id } }
          { type: { _eq: "STUDENT" } }
          { type: { _neq: "CANCELLED" } }
        ]
      }
      order_by: { updated_at: desc }
    ) {
      id
      status
      type
      enrollment_id
      createdAt: created_at
      updatedAt: updated_at
      certificate {
        id
        createdAt: created_at
      }
      event {
        id
        start
        end
        date
        detail {
          id
          title
          assets
          materials {
            id
            source
            attributes
            type
            name
          }
        }
        resources: event_resources {
          id
          user {
            given_name
          }
        }
      }
    }
    enrollmentCount: enrollment_aggregate(
      where: {
        _and: [
          { user_id: { _eq: $id } }
          {
            _or: [
              { status: { _eq: "deposit" } }
              { status: { _eq: "completed" } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    enrollment(
      order_by: { updated_at: desc }
      where: {
        _and: [
          { user_id: { _eq: $id } }
          {
            _or: [
              { status: { _eq: "pending" } }
              { status: { _eq: "deposit" } }
              { status: { _eq: "completed" } }
            ]
          }
        ]
      }
    ) {
      id
      created_at
      metadata
      status
      total
      balance
      deposit
      deposit_required
      registered
      courses {
        event {
          detail {
            materials {
              id
              source
              attributes
              type
              name
            }
          }
        }
      }
      uploads {
        id
        complete: client_complete
        code
      }
      forms {
        id
        responsesByResponses {
          complete: client_complete
          code
        }
      }
    }
    payment(
      where: {
        _and: [
          { status: { _eq: "pending" } }
          { enrollment_id: { _is_null: true } }
          { user: { _eq: $id } }
        ]
      }
    ) {
      id
      status
      amount
      metadata
      created_at
    }
    certificates: certificate(
      order_by: { updated_at: desc }
      where: { user_id: { _eq: $id } }
    ) {
      id
      attributes
      url
      event {
        id
        detail {
          id
          title
        }
      }
      createdAt: created_at
      updatedAt: updated_at
    }
    referralLink: referral_link(where: { user_id: { _eq: $id } }) {
      id
      link
      clicks
      credits
      enrollments {
        id
      }
    }
    documents: user_form(
      order_by: { updated_at: desc }
      where: {
        _and: [
          { model_session_purchase_id: { _is_null: true } }
          { enrollment_id: { _is_null: true } }
          { user: { _eq: $id } }
        ]
      }
    ) {
      id
      created_at
      updated_at
      course: eventByEvent {
        id
        detail {
          title
        }
      }
      response: responsesByResponses {
        id
        complete: client_complete
        code
      }
      form: formByForm {
        id
        title
      }
    }
    cohortMembers: cohort_member(where: { member: { id: { _eq: $id } } }) {
      id
      created_at
      updated_at
      type
      status
      addedBy {
        id
        full_name
      }
      notes {
        id
        internal
        note {
          created_at
          id
          title
          content
        }
      }
      cohort {
        id
        name
        status
        team: members(
          where: {
            _or: [
              { type: { _eq: "TUTOR" } }
              { type: { _eq: "ASSESSOR" } }
              { type: { _eq: "IQA" } }
            ]
          }
        ) {
          id
          created_at
          updated_at
          type
          status
          member {
            id
            full_name
          }
        }
        programme {
          id
          code
          title
          units {
            id
            created_at
            updated_at
            title
            description
            active
            unit_code
            e_learning_id
            assets
          }
        }
      }
      assignments {
        id
        created_at
        statuses {
          id
          created_at
          status
        }
        unit {
          title
          description
          active
          unit_code
          e_learning_id
          assets
        }
      }
    }
    requests: user_upload_request(
      order_by: { created_at: asc }
      where: {
        _and: [
          { user: { _eq: $id } }
          { model_session_purchase_id: { _is_null: true } }
          { enrollment_id: { _is_null: true } }
        ]
      }
    ) {
      id
      created_at
      updated_at
      complete: client_complete
      url
      code
      course: event {
        id
        detail {
          title
        }
      }
      request: uploadRequest {
        id
        title
        description
      }
    }
  }
`;

export const getRegistration = gql`
  query GetRegistration($id: uuid!, $userId: uuid!) {
    registration: registration_by_pk(id: $id) {
      id
      status
      type
      user {
        onlineId: elearning_id
        cognito_id
      }
      createdAt: created_at
      updatedAt: updated_at
      payments {
        id
        payment {
          id
          amount
          user
          invoiceId: invoice_id
          status
          transactionId: transaction_id
          currency
          createdAt: created_at
          updatedAt: updated_at
        }
      }
      event {
        id
        start
        end
        date
        price: seat_price
        createdAt: created_at
        updatedAt: updated_at
        detail {
          id
          title
        }
      }
      certificate {
        id
        url
        user_id
        created_at
        event {
          detail {
            title
          }
        }
      }
      forms(
        where: {
          _and: [
            { user: { _eq: $userId } }
            { formByForm: { title: { _nilike: "%feedback%" } } }
            { formByForm: { title: { _nilike: "%assessment%" } } }
          ]
        }
      ) {
        created_at
        updated_at
        id
        formByForm {
          id
          title
          content
          feedback
          formQuestions {
            id
            questionByQuestion {
              label
              type
              archived
            }
          }
        }
        responsesByResponses {
          responses
          complete: client_complete
          code
        }
      }
      uploads(where: { user: { _eq: $userId } }) {
        id
        created_at
        updated_at
        user
        course
        complete: client_complete
        url
        request: uploadRequest {
          title
          description
          id
          code
        }
        userByUser {
          cognito_id
        }
      }
    }
  }
`;

export const getEnrollment = gql`
  query getEnrollment($id: uuid!) {
    enrollment: enrollment_by_pk(id: $id) {
      id
      balance
      created_at
      updated_at
      total
      status
      metadata
      deposit
      registered
      deposit_required
      online_courses {
        id
        data
        created_at
        updated_at
        coassemble_course_id
        coassemble_group_id
        complete
        event_detail {
          title
        }
      }
      courses {
        id
        type
        created_at
        updated_at
        status
        package {
          title
        }
        event {
          start
          end
          date
          seat_price
          event_resources {
            user {
              given_name
            }
          }
          detail {
            title
          }
        }
      }
      forms {
        id
        created_at
        updated_at
        formByForm {
          id
          title
          content
          formQuestions {
            id
            order
            questionByQuestion {
              label
              type
              archived
            }
          }
        }
        responsesByResponses {
          id
          complete: client_complete
          responses
        }
      }
      credits {
        id
        credit_id
        created_at
        credit {
          id
          amount
          explanation
          redeemed
        }
      }
      discounts {
        id
        discount_id
        created_at
        discount {
          id
          value
          type
          active
          code
        }
      }
      uploads {
        id
        url
        complete: client_complete
        member
        updated_at
        created_at
        userByUser {
          cognito_id
        }
        uploadRequest {
          id
          title
          description
        }
      }
      payments {
        id
        created_at
        updated_at
        enrollment_id
        payment {
          created_at
          updated_at
          amount
          currency
          transaction_id
          method
          metadata
          status
        }
      }
    }
  }
`;

export const getMaterials = gql`
  query GetMaterials($id: uuid!) {
    materials: event_material(where: { event_detail_id: { _eq: $id } }) {
      id
      attributes
      name
      mode
      metadata
      source
      type
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getFeedbackRequest = gql`
  query GetFeedback($id: uuid!) {
    feedback: event_feedback(where: { event_id: { _eq: $id } }) {
      id
      user: user_id
      event: event_id
      form: form_id
      status
      response {
        id
        asset
        complete: client_complete
        responses
        form: formByForm {
          id
          title
          content
          formQuestions(order_by: { order: asc }) {
            id
            order
            properties
            question: questionByQuestion {
              id
              label
              type
              properties
              archived
            }
          }
        }
        createdAt
        updatedAt
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const scheduleIntakeCall = gql`
  mutation scheduleIntakeCall($data: intake_call_insert_input!) {
    insert_intake_call_one(object: $data) {
      id
    }
  }
`;

export const getLatestScheduledIntakeCall = gql`
  query getLatestScheduledIntakeCall($studentId: uuid!) {
    intake_call(
      where: { student_id: { _eq: $studentId } }
      limit: 1
      order_by: { date: desc }
    ) {
      date
      time
    }
  }
`;

export const addUrl = gql`
  mutation addUrl($id: uuid!, $url: String!) {
    update_user_upload_request_by_pk(
      pk_columns: { id: $id }
      _set: { url: $url }
    ) {
      url
      id
    }
  }
`;

export const getUserByCode = gql`
  query getUser($id: String!) {
    user(where: { code: { _eq: $id } }) {
      id
      email
      code
      contact_number
      access_code
      signed_in
      cognito_id
      set_password
      session_code
      onboard_code
    }
  }
`;

export const setCode = gql`
  mutation updateUserCode($id: uuid!, $code: String!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { access_code: $code }) {
      id
    }
  }
`;

export const setUserSessionCode = gql`
  mutation updateUserCode($id: uuid!, $code: String!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { session_code: $code }) {
      id
    }
  }
`;

export const setPassword = gql`
  mutation updateUser($id: uuid!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { set_password: true }) {
      id
    }
  }
`;

export const setSignedIn = gql`
  mutation updateUser($id: String!) {
    update_user(
      where: { cognito_id: { _eq: $id } }
      _set: { signed_in: true, set_password: true }
    ) {
      affected_rows
    }
  }
`;

export const getUserByCodeAccount = gql`
  query getUser(
    $id: String!
    $time: timestamptz
    $forms: [uuid!]!
    $uploads: [uuid!]!
  ) {
    user(where: { code: { _eq: $id } }) {
      id
      created_at
      points
      contact_number
      cognito_id
      set_password
      code
      forms: user_forms(
        where: {
          _and: [
            { formByForm: { id: { _in: $forms } } }
            { created_at: { _gte: $time } }
          ]
        }
      ) {
        id
        createdAt: created_at
        form: formByForm {
          title
          content
        }
        response: responsesByResponses {
          client_complete
          code
        }
      }
      uploads: upload_requests(
        where: {
          _and: [
            { uploadRequest: { id: { _in: $uploads } } }
            { created_at: { _gte: $time } }
          ]
        }
      ) {
        id
        createdAt: created_at
        client_complete
        code
        upload: uploadRequest {
          title
          description
        }
      }
      modelSessionPurchases(order_by: { created_at: desc }) {
        id
        created_at
        updated_at
        total
        status
        employee_id
        customer_id
        line_items
        forms(
          where: { responsesByResponses: { client_complete: { _eq: false } } }
        ) {
          id
          createdAt: created_at
          form: formByForm {
            title
            content
          }
          response: responsesByResponses {
            client_complete
            code
          }
        }
        uploads(where: { client_complete: { _eq: false } }) {
          id
          createdAt: created_at
          client_complete
          code
          upload: uploadRequest {
            title
            description
          }
        }
        registrations {
          id
          event {
            detail {
              materials(
                where: { attributes: { _contains: { type: "MODEL" } } }
              ) {
                id
                name
                source
                attributes
              }
            }
          }
        }
        customer {
          id
          contact_number
          name: given_name
          surname: family_name
          email
        }
      }
      enrollments(
        where: {
          _or: [
            { status: { _eq: "deposit" } }
            { status: { _eq: "completed" } }
          ]
        }
      ) {
        id
        created_at
        call
        metadata
        forms(
          where: { responsesByResponses: { client_complete: { _eq: false } } }
        ) {
          id
          createdAt: created_at
          form: formByForm {
            title
            content
          }
          response: responsesByResponses {
            client_complete
            code
          }
        }
        uploads(where: { client_complete: { _eq: false } }) {
          id
          createdAt: created_at
          client_complete
          code
          upload: uploadRequest {
            title
            description
          }
        }
      }
    }
  }
`;
