import "../styles/index.css";
import { Amplify } from "aws-amplify";
import * as Sentry from "@sentry/nextjs";
import Script from "next/script";
import { Suspense, useEffect } from "react";
import { Session } from "components/providers/session";
// import { LiveChatLoaderProvider, Intercom } from "react-live-chat-loader";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { Montserrat } from "next/font/google";

const mont = Montserrat({
  weight: ["100", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

Amplify.configure({
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_ID_POOL,
  ssr: true,
});

export function reportWebVitals(metric) {
  // @ts-ignore
  window.gtag &&
    window.gtag("event", metric.name, {
      event_category:
        metric.label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(
        metric.name === "CLS" ? metric.value * 1000 : metric.value
      ),
      event_label: metric.id,
      non_interaction: true,
    });
}

function CosmeticCollege({ Component, pageProps }) {
  return (
    <>
      <Script strategy="lazyOnload" src={process.env.NEXT_PUBLIC_SNAP_SCRIPT} />
      {/* <Script id="snap-check" strategy="lazyOnload">{`
          setTimeout(() => {
            console.log('init snap finance');
            snapuk.init('${process.env.NEXT_PUBLIC_SNAP_FINANCE_KEY}');
          }, [2000])
        `}</Script> */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-V6N2LWSWX8"
        strategy="afterInteractive"
      />
      <Script strategy="lazyOnload" id="fb-script">
        {`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '803522774309547');fbq('track', 'PageView');`}
      </Script>
      <Script strategy="lazyOnload" id="hp-script">
        {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n([o])};heap.load("1614331371");`}
      </Script>
      <Script
        strategy="lazyOnload"
        id="scd-script"
        src="https://calendar.google.com/calendar/scheduling-button-script.js"
      ></Script>
      <Script strategy="afterInteractive" id="fs-script">
        {`
        !function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();
        `}
      </Script>
      {/* <Script>{`  (function(m,n,e,t,l,o,g,y){
                      if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
                      o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
                      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
                      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
                      g.anonymize=function(){g.identify(!!0)};
                      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
                      g.log = function(a,b){g("log",[a,b])};
                      g.consent=function(a){g("consent",!arguments.length||a)};
                      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
                      g.clearUserCookie=function(){};
                      g.setVars=function(n, p){g('setVars',[n,p]);};
                      g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
                      if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
                      g._v="1.3.0";
                  })(window,document,window['_fs_namespace'],'script','user');`}</Script> */}
      <Script strategy="lazyOnload" id="tk-script">
        {`
!function (w, d, t) {w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('CIKPLLBC77U220R2B8L0'); ttq.page(); }(window, document, 'ttq');`}
      </Script>
      <Script
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XxnURE"
        strategy="afterInteractive"
      />
      <Script
        src="https://x.klarnacdn.net/kp/lib/v1/api.js"
        strategy="lazyOnload"
      />
      <Script
        src="https://eu-library.klarnaservices.com/lib.js"
        strategy="lazyOnload"
        data-client-id="866c2b54-980a-5be9-85a4-9d408a62a054"
      />
      <Script strategy="afterInteractive" id="mc-script">
        {`function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src="https://tracker.metricool.com/resources/be.js",c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"46e43e3ba383eaf7438918c24765a626"})});`}
      </Script>
      {/*<Script
        src="https://sdk.snapfinance.co.uk/v1/snapuk.min.js"
        strategy="afterInteractive"
      />*/}
      <Script strategy="afterInteractive" id="clarity-sc">
        {`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "lrcn4tue73");`}
      </Script>
      <Suspense fallback={<div>Loading...</div>}>
        <Session>
          {/* <LiveChatLoaderProvider
            providerKey="ha8vli9e"
            provider="intercom"
            idlePeriod={500}
          > */}
            <>
              <Component {...pageProps} />
              {/* <Intercom /> */}
            </>
          {/* </LiveChatLoaderProvider> */}
        </Session>
      </Suspense>
      <SpeedInsights />
    </>
  );
}

export default CosmeticCollege;
